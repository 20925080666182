<section class="container-title">
  <article class="container-row article-container">
    <div class="main-title mt30px">
      <span class="title-set">Jobs</span><br />
    </div>
    <div class="content-search-add mt30px">
      <mat-form-field class="input-search" appearance="outline">
        <mat-icon matPrefix class="icon-grey">search</mat-icon>
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchInput"
          (input)="changeQuery(searchInput)"
        />
      </mat-form-field>
    </div>
  </article>
  <mat-form-field class="input-new" appearance="outline">
    <mat-icon matPrefix class="icon-grey">search</mat-icon>
    <input
      matInput
      placeholder="Search"
      [(ngModel)]="searchInput"
      (input)="changeQuery(searchInput)"
    />
  </mat-form-field>
</section>
<div *ngIf="isLoading">
  <section class="container-column h-parser main-section">
    <div class="container">
      <div class="csv-result-table job-results">
        <table
          mat-table
          [dataSource]="listJobs"
          matSort
          class="mat-elevation-z8 csv-parser-content"
          (matSortChange)="announceSortChange($event)"
          matSortDisableClear
          *ngIf="isLoading && allJobsLoaded"
        >
          <ng-container matColumnDef="created_on">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="createdDate"
              sortActionDescription="Created on"
              class="created_on"
            >
              <span class="pl46px">Created on</span>
            </th>
            <td
              mat-cell
              *matCellDef="let element; let i = index"
              class="created_on"
            >
              <span class="pl46px"
                >{{ getLocaleDateOfDay(element.createdDate) }}<br />
                <span class="dateSpan">{{
                  getLocaleTimeOfDay(element.createdDate)
                }}</span></span
              >
            </td>
          </ng-container>
          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef class="created-by">
              Created by
            </th>
            <td mat-cell *matCellDef="let element" class="created-by">
              {{ element.email }}
              <!-- {{element.createdDate}} -->
              <!-- {{(today | date ) == (job.date | date ) ? 'Today' : (job.date | date: 'MM/dd/yyyy')}}                           -->
            </td>
          </ng-container>
          <ng-container matColumnDef="sample_set_name">
            <th mat-header-cell *matHeaderCellDef class="set-column">
              Sample Set Name / Job ID
            </th>
            <td mat-cell *matCellDef="let element" class="set-column">
              <span class="bold">{{ element.sampleSetName }}</span
              ><br />
              {{ element.id }}
            </td>
          </ng-container>
          <!-- <ng-container matColumnDef="concPath">
                          <th mat-header-cell *matHeaderCellDef></th>
                          <td mat-cell *matCellDef="let element"></td>
                        </ng-container> -->
          <ng-container matColumnDef="program">
            <th mat-header-cell *matHeaderCellDef class="set-column">
              Program
            </th>
            <td mat-cell *matCellDef="let element" class="set-column">
              <span class="bold"> {{ element.program.programName }}</span
              ><br />
              <span>{{ element.program.programVersion }}</span>
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="status-column">
              Status
            </th>
            <td mat-cell *matCellDef="let element" class="status-column">
              <div [ngSwitch]="element.jobStatus" class="column-status">
                <div>
                  <div *ngSwitchCase="'CREATED'">
                    <div
                      class="center-center gap15 status-text"
                      appTooltip='
                                            <div class="custom-tooltip-panel">
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle circle-small">
                                              1
                                                  </div>
                                                  <div class="grey-text">File Preparation</div>
                                                </div>
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle circle-small">
                                              2
                                                  </div>
                                                  <div class="grey-text">Feature Extraction</div>
                                                </div>
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle circle-small">
                                              3
                                                  </div>
                                                  <div class="grey-text">Inference</div>
                                                </div>
                                                <div class="container-row gap8">
                                                  <div class="circle circle-small">
                                              4
                                                  </div>
                                                  <div class="grey-text">Reporting</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="triangle"></div>
                                      '
                    >
                      <div class="spinner-executing">
                        <div class="spinner-background"></div>
                        <mat-spinner></mat-spinner>
                      </div>

                      <span class="info-step">
                        1/4 File Preparation... <br />
                        Estimated completion on
                        <span>
                          {{
                            getLocaleTimeOfDayRounded(element.expectedEndDate)
                          }}</span
                        >
                        (total time ≈
                        {{
                          timeDifference(
                            element.createdDate,
                            element.expectedEndDate
                          )
                        }}
                        min.)</span
                      >
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'STARTED'" class="container-column">
                  <div
                    class="center-center gap15 status-text"
                    appTooltip='
                                        <div class="custom-tooltip-panel">
                                            <div class="container-row gap8 mb8px">
                                              <div class="circle green-circle">
                                          1
                                              </div>
                                              <div class="grey-text">File Preparation</div>
                                            </div>
                                            <div class="container-row gap8 mb8px">
                                              <div class="circle circle-small">
                                          2
                                              </div>
                                              <div class="grey-text">Feature Extraction</div>
                                            </div>
                                            <div class="container-row gap8 mb8px">
                                              <div class="circle circle-small">
                                          3
                                              </div>
                                              <div class="grey-text">Inference</div>
                                            </div>
                                            <div class="container-row gap8">
                                              <div class="circle circle-small">
                                          4
                                              </div>
                                              <div class="grey-text">Reporting</div>
                                            </div>
                                          </div>
                                        </div>
                                        <div class="triangle"></div>
                                  '
                  >
                    <div class="spinner-executing">
                      <div class="spinner-background"></div>
                      <mat-spinner></mat-spinner>
                    </div>
                    <span class="info-step">
                      2/4 Feature Extraction... <br />
                      Estimated completion on
                      <span>
                        {{
                          getLocaleTimeOfDayRounded(element.expectedEndDate)
                        }}</span
                      >
                      (total time ≈
                      {{
                        timeDifference(
                          element.createdDate,
                          element.expectedEndDate
                        )
                      }}
                      min.)</span
                    >
                  </div>
                </div>
                <div *ngSwitchCase="'WORKING'" class="container-column">
                  <div class="center-center gap15">
                    <div
                      class="center-center gap15 status-text"
                      appTooltip='
                                            <div class="custom-tooltip-panel">
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle green-circle">
                                              1
                                                  </div>
                                                  <div class="grey-text">File Preparation</div>
                                                </div>
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle green-circle">
                                              2
                                                  </div>
                                                  <div class="grey-text">Feature Extraction</div>
                                                </div>
                                                <div class="container-row gap8 mb8px">
                                                  <div class="circle green-circle">
                                              3
                                                  </div>
                                                  <div class="grey-text">Inference</div>
                                                </div>
                                                <div class="container-row gap8">
                                                  <div class="circle circle-small">
                                              4
                                                  </div>
                                                  <div class="grey-text">Reporting</div>
                                                </div>
                                              </div>
                                            </div>
                                            <div class="triangle"></div>
                                      '
                    >
                      <div class="spinner-executing">
                        <div class="spinner-background"></div>
                        <mat-spinner></mat-spinner>
                      </div>
                      <span class="info-step">
                        3/4 Inference... <br />
                        Estimated completion on
                        <span>{{
                          getLocaleTimeOfDayRounded(element.expectedEndDate)
                        }}</span>
                        (total time ≈
                        {{
                          timeDifference(
                            element.createdDate,
                            element.expectedEndDate
                          )
                        }}
                        min.)
                      </span>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'COMPLETED'">
                  <div class="center-center gap15">
                    <div
                      class="center-center gap15 status-text"
                      appTooltip='
                                            <div class="custom-tooltip-panel">
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            1
                                                </div>
                                                <div class="grey-text">File Preparation</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            2
                                                </div>
                                                <div class="grey-text">Feature Extraction</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            3
                                                </div>
                                                <div class="grey-text">Inference</div>
                                              </div>
                                              <div class="container-row gap8">
                                                <div class="circle circle-small">
                                            4
                                                </div>
                                                <div class="grey-text">Reporting</div>
                                              </div>
                                              
                                            </div>

                                            <div class="triangle"></div>  
                                          '
                    >
                      <img src="../../../../../../assets/icons/check.svg" />
                      <span class="info-step"> 3/4 Reporting... </span>
                    </div>

                    <div class="container-column btn-end ov">
                      <a
                        *ngIf="downLoadStartValue !== element.id"
                        class="download"
                        [attr.disabled]="startDownloading ? '' : null"
                        (click)="
                          startDownloading ? null : downloadFile(element.id)
                        "
                      >
                        Download Results
                      </a>

                      <div
                        *ngIf="downLoadStartValue === element.id"
                        class="spinner"
                      >
                        <div class="pending-download">
                          <div class="spinner-executing">
                            <div class="spinner-background"></div>
                            <mat-spinner [diameter]="30"></mat-spinner>
                          </div>
                          <span class="cancel-download">Generating file</span>
                          <button mat-button (click)="cacelDownload()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'REPORTED'">
                  <div class="center-center gap15">
                    <div
                      class="center-center gap15"
                      appTooltip='
                                            <div class="custom-tooltip-panel">
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            1
                                                </div>
                                                <div class="grey-text">File Preparation</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            2
                                                </div>
                                                <div class="grey-text">Feature Extraction</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            3
                                                </div>
                                                <div class="grey-text">Inference</div>
                                              </div>
                                              <div class="container-row gap8">
                                                <div class="circle green-circle">
                                            4
                                                </div>
                                                <div class="grey-text">Reporting</div>
                                              </div>
                                              
                                            </div>
                                            <div class="triangle"></div>
                                          '
                    >
                      <img src="../../../../../../assets/icons/check.svg" />
                      <span class="info-step">Reported</span>
                    </div>

                    <div class="container-column btn-end ov">
                      <a
                        *ngIf="downLoadStartValue !== element.id"
                        class="download"
                        [attr.disabled]="startDownloading ? '' : null"
                        (click)="
                          startDownloading ? null : downloadFile(element.id)
                        "
                      >
                        Download Results
                      </a>

                      <div
                        *ngIf="downLoadStartValue === element.id"
                        class="spinner"
                      >
                        <div class="pending-download">
                          <div class="spinner-executing">
                            <div class="spinner-background"></div>
                            <mat-spinner [diameter]="30"></mat-spinner>
                          </div>
                          <span class="cancel-download">Generating file</span>
                          <button mat-button (click)="cacelDownload()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'REPORT_ERROR'">
                  <div class="center-center gap15">
                    <div
                      class="center-center gap15"
                      appTooltip='
                                            <div class="custom-tooltip-panel">
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            1
                                                </div>
                                                <div class="grey-text">File Preparation</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            2
                                                </div>
                                                <div class="grey-text">Feature Extraction</div>
                                              </div>
                                              <div class="container-row gap8 mb8px">
                                                <div class="circle green-circle">
                                            3
                                                </div>
                                                <div class="grey-text">Inference</div>
                                              </div>
                                              <div class="container-row gap8">
                                                <div class="circle green-circle">
                                            4
                                                </div>
                                                <div class="grey-text">Reporting</div>
                                              </div>
                                              
                                            </div>
                                            <div class="triangle"></div>
                                          '
                    >
                      <img src="../../../../../../assets/icons/check.svg" />
                      <span class="info-step">Reported error</span>
                    </div>

                    <div class="container-column btn-end ov">
                      <a
                        *ngIf="downLoadStartValue !== element.id"
                        class="download"
                        [attr.disabled]="startDownloading ? '' : null"
                        (click)="
                          startDownloading ? null : downloadFile(element.id)
                        "
                      >
                        Download Results
                      </a>

                      <div
                        *ngIf="downLoadStartValue === element.id"
                        class="spinner"
                      >
                        <div class="pending-download">
                          <div class="spinner-executing">
                            <div class="spinner-background"></div>
                            <mat-spinner [diameter]="30"></mat-spinner>
                          </div>
                          <span class="cancel-download">Generating file</span>
                          <button mat-button (click)="cacelDownload()">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngSwitchCase="'ERROR'">
                  <div class="center-center gap15">
                    <img src="../../../../../../assets/icons/info-red.svg" />
                    <span>
                      <ng-container
                        *ngIf="
                          getFirstErrorElement(element.settings) as firstError
                        "
                      >
                        <span
                          matTooltip="{{ firstError.attributes[0].content }}"
                          class="small-text-error"
                        >
                          {{ firstError.attributes[0].content }}
                        </span>
                      </ng-container>
                    </span>
                  </div>
                </div>
                <div *ngSwitchCase="'TIMEOUT'">TIMEOUT</div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              <span class="pl46px">No data matching the filter</span>
            </td>
          </tr>
        </table>
        <table
          mat-table
          [dataSource]="listJobs"
          matSort
          class="mat-elevation-z8 csv-parser-content"
          (matSortChange)="announceSortChange($event)"
          matSortDisableClear
          *ngIf="(!isLoading || !allJobsLoaded) && !isSearched && !isSorted"
        >
          <ng-container matColumnDef="created_on">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header="created_date"
              sortActionDescription="Created on"
              class="created_on"
            >
              <span class="pl46px">Created on</span>
            </th>
          </ng-container>
          <ng-container matColumnDef="created_by">
            <th mat-header-cell *matHeaderCellDef class="created-by">
              Created by
            </th>
          </ng-container>
          <ng-container matColumnDef="sample_set_name">
            <th mat-header-cell *matHeaderCellDef class="set-column">
              Sample Set Name / Job ID
            </th>
          </ng-container>
          <!-- <ng-container matColumnDef="concPath">
                      <th mat-header-cell *matHeaderCellDef></th>
                      <td mat-cell *matCellDef="let element"></td>
                    </ng-container> -->
          <ng-container matColumnDef="program">
            <th mat-header-cell *matHeaderCellDef class="set-column">
              Program
            </th>
          </ng-container>
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="status-column">
              Status
            </th>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        </table>
        <div *ngIf="isNextPage">
          <div class="container-spinner">
            <div class="spinner">
              <mat-spinner></mat-spinner>
            </div>
          </div>
        </div>
        <mat-paginator
          class="paginator"
          *ngIf="isLoading && allJobsLoaded"
          [length]="totalJobs"
          [pageSize]="jobsLimit"
          [pageSizeOptions]="[10]"
          (page)="pageChanged($event)"
        ></mat-paginator>
      </div>
    </div>
  </section>

  <div *ngIf="!isLoading || !allJobsLoaded || isSearched || isSorted">
    <div *ngIf="!this.commonService.isConfigError" class="container-spinner">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>

  <div *ngIf="!isLoading || !allJobsLoaded">
    <div *ngIf="!this.commonService.isConfigError" class="container-spinner">
      <div class="spinner">
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</div>
